import '@babel/polyfill';
import FontFaceObserver from 'fontfaceobserver';
import { ICON_SPRITE_URL } from './constants';
import loadSvgSprite from './utils/load-svg-sprite';

/**
 * Load fonts via FontFaceObserver if supported
 */
try {
  if (sessionStorage.fontsLoaded) {
    document.documentElement.className += ' has-optimized-font has-headline-font has-fonts';
  } else {
    const optimizedSubset = new FontFaceObserver('Roboto Optimized');

    optimizedSubset.load().then(() => {
      document.documentElement.className += ' has-optimized-font';

      const fonts = [
        new FontFaceObserver('URW Classico'),
        new FontFaceObserver('Roboto'),
        new FontFaceObserver('Roboto', { weight: 'bold' }),
        new FontFaceObserver('Roboto', { style: 'italic' }),
        new FontFaceObserver('Roboto', { weight: 'bold', style: 'italic' }),
      ];

      Promise.all(fonts.map(font => font.load())).then(() => {
        document.documentElement.className += ' has-optimized-font has-headline-font has-fonts';
        sessionStorage.fontsLoaded = true;
      });
    });
  }
} catch (e) {
  // Font loading failed
  document.documentElement.className += ' has-optimized-font has-headline-font has-fonts';
}

/**
 * Inject icons
 */
loadSvgSprite(ICON_SPRITE_URL);
